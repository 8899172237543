.SubscribeForm{
    padding: 80px 20px;
    color: var(--dark-blue);
    margin: auto;
}

.SubscribeForm-header{
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 30px;
}

.SubscribeForm-title{
    font-size: 32px;
    font-weight: 900;
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
}

.SubscribeForm input, select{
    font-size: 16px;
    display: block;
    margin: 10px auto;
    width: 250px;
    border: 1px solid var(--dark-blue);
    border-radius: 5px;
    padding: 10px;
}

.SubscribeForm-dataCheckbox{
    width: 80%;
    margin: auto;
    line-height: 1.5;
}

.SubscribeForm input[type=checkbox]{
    display: inline;
    width: 20px;
}

.SubscribeForm-dataCheckbox label{
    overflow-wrap: break-word;
    line-height: normal;
    cursor:  pointer;
}


.SubscribeForm-btn{
    cursor: pointer;
    background-color: var(--dark-blue);
    color: var(--beige);
    font-size: 16px;
    font-weight: 600;
    padding: 10px;
    border-radius: 12px;
    display: block;
    margin: 20px auto;
}


.SubscribeForm-btn[disabled]{
    cursor: auto;
    background-color: var(--light-grey);
    color: var(--dark-blue);
}

.SubscribeForm-tryAgain{
    color: rgb(238, 80, 80);
    text-align: center;
}

@media only screen and (max-width: 1024px) {
    .SubscribeForm {
        width: 100%;
    }
}

@media only screen and (max-width: 600px) {
    .SubscribeForm {
        padding: 20px
    }
}

