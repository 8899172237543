
.OrderListItem-enter {
  height: 0px;
  opacity: 0;
  transform: translateX(-400px);
}

.OrderListItem-enter.OrderListItem-enter-active {
  height: 72px;
  opacity: 1;
  transform: translateX(0px);
  transition: height 200ms, opacity 300ms 300ms, transform 300ms 300ms;
}
