.LandingPage{
    height: 100vh;
    background-image: linear-gradient(to bottom right, var(--dark-blue), var(--beige));
    overflow: hidden;
}

.LandingPage-content{
    padding: 100px 10px;
}

.LandingPage-header{
    background-color: var(--dark-blue);
    color: var(--beige);
    display: flex;
    padding: 0 15px;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
}

.LandingPage-name{
    font-size: 26px;
    font-weight: 800;
    padding: 10px;
}

.LandingPage-links{
    display: flex;
    gap: 20px;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
}

.LandingPage-links div{
    padding: 30px 0;
    margin: 0 10px;
    border-bottom: 2px solid transparent;
}

.LandingPage-links div:hover{
    color: var(--light-grey);
    transform: scale(1.02);
    border-bottom: 2px solid var(--beige);
}

.LandingPage-title{
    font-size: 45px;
    font-weight: 700;
    color: var(--beige);
    text-align: center;
}

.LandingPage-subTitle{
    font-size: 30px;
    color: var(--beige);
    text-align: center;
    padding: 30px;
    letter-spacing: 2px;
}

.LandingPage-image{
    width: 500px;
    margin: auto;
    display: block;
    border-radius: 15px;
}

.LandingPage-orderBook {
  margin: auto;
  margin-top: 48px;
  width: 100%;
  max-width: 800px;
  height: 720px;
  max-height: 70%;
  overflow: hidden;
  --mask: linear-gradient(to bottom,
      rgba(0,0,0, 1) 0,   rgba(0,0,0, 1) 40%,
      rgba(0,0,0, 0) 95%, rgba(0,0,0, 0) 0
  ) 100% 50% / 100% 100% repeat-x;

  mask: var(--mask);
}

@media only screen and (max-width: 1024px) {
    .LandingPage{
        height: auto;
    }
}

@media only screen and (max-width: 600px) {
    .LandingPage-benefitLink {
       display: none;
    }

    .LandingPage-image{
        width: 250px
    }
}
