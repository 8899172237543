.OrderListItem {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px;
  background-color: white;
  color: var(--dark-blue);
  border-radius: 4px;
  margin: 4px;
  overflow: hidden;
}

.OrderListItem-icon {
  flex-shrink: 0;
}

.OrderListItem-amount {
  font-weight: 700;
}

.OrderListItem-gap {
  flex-grow: 1;
}

.OrderListItem-nowrap {
  white-space: nowrap;
}
