.SuccessfulPostPage{
    color: var(--beige);
    text-shadow: 2px 2px var(--dark-blue);
    font-size: 20px;
    font-weight: 600;
    margin: 200px auto;
    width: 450px;
    padding: 20px;
    text-align: center;
    
}


.SuccessfulPostPage div:first-child{
    font-size: 30px;
    margin: 20px 0;
}

@media only screen and (max-width: 600px) {

    .SuccessfulPostPage{
      width: 95%;
    }
  }