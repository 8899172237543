:root{
  --beige: #f1efea;
  --light-grey: #e4e1dd;
  --dark-grey: #a19f9b;
  --light-blue: #6ea0b6;
  --dark-blue:#005d8a;
  --light-purple:  #edeeff;
}

*{
  margin: 0;
  padding: 0;
  box-sizing:border-box;
  font-family: 'Ariel', sans-serif;
}

