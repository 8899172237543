.BenefitPage2{
    background-color: var(--dark-blue);
    color: var(--beige);
    height: 100vh;
    display: flex;
    padding: 120px 20px;
    margin: auto;
}

.BenefitPage2-content{
    display: flex;
    gap: 30px;
    margin: auto;
}

.BenefitPage2-image{
    border-radius: 15px;
    width: 300px;
    object-fit: contain;
    margin: auto;
}

.BenefitPage2-textContainer{
    width: 70%;
    margin: 0 auto;
    font-size: 20px;
    display: flex;
    flex-direction: column;
}

.BenefitPage2-title{
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 65px;
    text-align: center;
}


.BenefitPage2-buyButton, .BenefitPage2-sellButton{
    border-radius: 3px;
    background-color: var(--light-purple);
    color: var(--dark-blue);
    border: 1px solid var(--beige);
    padding: 10px;
    margin: 50px;
    font-weight: 700;
    font-size: 16px;
    align-self: flex-end;
    cursor: pointer;
}

@media only screen and (max-width: 1200px) {
    .BenefitPage2{
        height: auto;
    }

    .BenefitPage2-image{
        height: 50%;
    }

    .BenefitPage2-content{
        flex-direction: column;
    }
 }