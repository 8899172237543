
.SubscribePage {
    height: 100vh;
    background-image: linear-gradient(to bottom right, var(--dark-blue), var(--beige));
    padding: 80px 0;
  }
  
  .SubscribePage-content{
    background-color: var(--beige);
    margin: auto;
    border: 1px solid var(--dark-blue);
    width: 80%;
    border-radius: 15px;
    display: flex;
    overflow: hidden;
  }
  
  .SubscribePage-image{
    width: 40%;
    object-fit: cover;
  }
  
  @media only screen and (max-width: 1024px) {
    .SubscribePage-image {
      display: none;
    }
    .SubscribePage-content{
      border: none;
    }
  }
  
  @media only screen and (max-width: 600px) {
  
    .SubscribePage-content{
      width: 90%;
      border: none;
    }
  }