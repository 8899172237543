.BenefitPage{
    height: 100vh;
    background-color: #edeeff;
    margin: auto;
    padding: 100px 0;
}

.BenefitPage-title{
    text-align: center;
    font-size: 42px;
    font-weight: 700;
    color: var(--dark-blue);
    padding: 20px;
}

.BenefitPage-subtitle{
    font-size: 32px;
    color: var(--dark-blue);
    letter-spacing: 2px;
    text-align: center;
    margin-top: 30px;
}

.BenefitPage-benefitsContainer{
    padding: 50px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    color: var(--dark-blue);
}

.BenefitPage-benefitCard{
    background-color: white;
    width: 220px;
    height: 250px;
    border: 1px solid var(--beige);
    padding: 20px;
    margin: 20px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(26, 28, 77, 0.1) 0px 6px 20px 0px;
    align-items: flex-start;
    transition: opacity 0.6s ease-in;
}

.BenefitPage-benefitCard-hidden{
    opacity: 0;
}

.BenefitPage-benefitCardName{
    font-weight: 900;
    font-size: 18px;
    padding: 10px 0;
}

.BenefitPage-benefitCardName span{
    text-align: center;
    vertical-align: middle;
}

.BenefitPage-benefitCardDetails{
    line-height: 1.6;
}

@media only screen and (max-width: 1024px) {
    .BenefitPage{
        height: auto;
    }
 }
